import * as React from 'react';
import Box from '@mui/material/Box';

const CorrCovIconActive = () => {
  return (
    <React.Fragment>
      <Box
        component="img"
        sx={{
          height: 35,
          width: 35,
        }}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIklEQVR4nO3cQWrDQAwF0H+Hdt/LeqFzNfQOvk2KAzWklG6lid8DQcgqzLdCGIkkAAAAAAAAAAAX8ZXkrnKcwS0DCCNPZ9Du8UFq2y9dEcjeHoJAtv6DF8jWf9gC2foPePlAXrne3z7Ogz9e/35vZCDdT2jpEIHU5A5RGRPIcV0gkDzO4LM7DAAAAAD+ZQ0o512eNaDMu9hsZ0C1DZyHdE/sysRQIDV5Ytj9hJYOEUit1CE/6zKrr/vEGtC+ZI3vkKtVpgWiMiYQa0A5w7AGBAAAADCbNaBYA7oPvl1uZx6yDZyHdA+IyoBKIDV5Ytj9hJYOEUit0iF//WPOimtB8Strb//qeZmtk+4DKYE8B6IypkOsAcUaEAAAAAAAAACQa/kGR22cvQQAvxgAAAAASUVORK5CYII="
      />
    </React.Fragment>
  );
};

export default CorrCovIconActive;
