import * as React from 'react';
import Box from '@mui/material/Box';

const VolSpreadIconActive = () => {
  return (
    <React.Fragment>
      <Box
        component="img"
        sx={{
          height: 40,
          width: 40,
        }}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADkUlEQVR4nO3bPWiTQRzH8a9VoaA4u2jSKlYnqyi+ggVpJh0EQXDQTSwW1F2LLSI66aK2g2+4lKqTDuqiaEUoWsVBKlW0LUrVpS8ipr5Erlww1ASetEl6z93vAw9PSWhyl3vu7n/P/R8QERERERERERERERER+ScJdABDwIQ9t9vXpcJSwDiQyXOMAY2VLlDIarKNsaquIdPcdCPT1vJi8ryyriHbKKNAAk8lHRsaOrKNcfpk339HTqNcxEMpB4cGc0Fkmptu5m2QQwe7suUbwDM1jg4NafPdrcef520Q87ot2w880+Ho0DAYsYd8wDNDjg4NZv7K1CbX5y1X3Ypt2XJdwDPpiEODmegrZR5wHvhjvtv0UnNhtLX0Tp5zGsMMpUvxSDUwErGH/ATO2v8pt4fAHWC3/dHzBRujvq1DdgDv7FFwDsm5Gi8BnUBPGcuUvdrXAnPs3wk7fw3Y3jxghymveoZxDdhu1xljRQwNy+x5C1BVorIsAC4DvQRkIXAGeJ3nvcZpDA33gLslWGSuAfpsDzQNE2tRKm66/l773lVgcYHPShQ5NJjesXlKrylmkWnKdRj4DOzBA1Eqvhp4BDwDNpWxLD12fqmPuMg0veI28NQuTINYXZur/CtwoIRjfSHVNgIbj7jI/AacsuFtUKtrM1FW0peIIfQwnnF1dZ0O9f6TqxUfDPX+k6sVb4+4yPTu/pOrFU8Wucj0hssVbwzp/lNcKp4I5f7TVMFWXERERERk9iUdS0sNWsrBtNRg1TialhqsDkfTUoM15OjGWbDSETfOfgG1s13YEAxH7CEjdv+902YpesOl8HIj8L2IjTOTuHfU7n7eBxpiUMfYhJf1tnfsn8bG2Xz7f105nzXXwTrGKrzsB3aVaOPMpKe+BY45VsdYhZfVJd44Mymq7x2ro/Ph5RLguud1jFVe1kvgiOd1LHle1scylqGGMHPPZpSXZcLQW8CGCJ+ZjBBeLgJaCTv3bEZ5WcuBfcAboBvYmfOoWK5UhPDSPEzzGDjnWB2dybApJrysso1hnuN4ZRvJxP/FhNAP7AM/5X60IS65Z3lNJ7zcah+W+QScAK5EDC/7cxZslRRM7tk6uzr+HXHyNJOsVMBEnMLLEAzGKbwMQXucwssQJOMWXoagMW7hZQgSoYSXIiIiIiIiIiJSUU8K3AgM8ejGAd0O/BAZRw6TCSMiIiIiIiIiIiIiIiIU9hd/+uGnKa7ztAAAAABJRU5ErkJggg=="
      />
    </React.Fragment>
  );
};

export default VolSpreadIconActive;
