import * as React from 'react';
import Box from '@mui/material/Box';

const VolSpreadIcon = () => {
  return (
    <React.Fragment>
      <Box
        component="img"
        sx={{
          height: 40,
          width: 40,
        }}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADjklEQVR4nO3bPWiTQRzH8W99gYLi7KJJ1N0qgq9FQRLaWgdBEBx0c1V3B10EJ13UZvANlyI46aAuik0Rq4g4iFJF26JU20LfkKa+RK5cMNQELm2S3vPc7wMPT0locpd77u5/z/0fEBEREREREREREREREfknCWSBIWDWnrvs69JgmaampimgUOaYBNKNLlDIUsXG6OjoLORyfYWxsem5c3v7gWKjTAAJYirp2dCQLTbGzEzhv6OkUa4SQxkPhwZzQRR6e1+UbZCenufF8g0QMylPh4a8+e6RkcmyDWJet2WbIWayng4Ng4495DMxM+Tp0GDmr0Jr696y5Wpr6yiW6woxk3ccGsxE3ygrgMvAH/PdppeaC2N0dGruXNIYZihdT4w0A+OOPeQncNH+T709Ae4Dh+2PXi7YmIjbOqQT+GiPinNIydV4DegG+upYpuLVvhVosn8n7Pw1YHvzgB2mYtUzjFvAfrvOmKxiaNhoz7uBZTUqyyrgOvCKgKwGLgBvy7yXXsDQ8BB4UINF5hbgne2BpmEizaXipusfte/dBNZW+KxElUOD6R275vWaahaZplwngW/AEWLApeKbgafAS2BnHcvSZ+eXFsdFpukV94BnZmFKIKtrc5WPACdqONZX0mwjsCnHReY0cN6Gt0Gtrs1E2UjfHUPoYWLG19V1PtT7T75WfDDU+0++VrzLcZEZu/tPvlY8WeUiMzZ8rng6pPtPUal4IpT7T/MFW3ERERERkaWX9CwtNWgZD9NSg5XyNC01WFlP01KDNeTpxlmw8o4bZ7+ADUtd2BAMO/aQcbv/3m2zFGPDp/ByB/Cjio0zk7h32u5+PgL2RaCOkQkvW2zvOL6AjbOV9v/ulHzWcg/rGKnwsh84VKONM5Oe+gE441kdIxVeNtd448ykqH7yrI7eh5frgNsxr2Ok8rJeA6diXsea52V9qWMZUoSZe7aovCwTht4Ftjt8ZtIhvFwDnCPs3LNF5WVtAo4B74EccLDkUbFSGYfw0jxM0wNc8qyO3mTYVBNeLrONYZ7jeGMbycT/1YTQj+0DP/V+tCEquWdlLSS83GMflvkKnAVuOIaX/SULtkYKJvdsm10d/3acPM0kKw0wG6XwMgSDUQovQ9AVpfAyBMmohZchSEctvAxBIpTwUkREREREREREGqq3wo3AEI8cHsh58EMUPDlMJoyIiIiIiIiIiIiIiIhQ2V+j2Bp/SPkp9AAAAABJRU5ErkJggg=="
      />
    </React.Fragment>
  );
};

export default VolSpreadIcon;
